import Footer from './Footer'
import Background from '../../Assets/hero-bg.png'
import { Card, Image, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Logo from '../../Assets/logo.png'

const Layout = ({children, style}) => {
  const backgroundStyle = {
    backgroundImage: `url("${Background}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%'
    /* Add other background properties as needed */
  };
  return(
    <div style={backgroundStyle}>
      <Container style={{...style, paddingTop: 20}}>
        <Image src={Logo} size="tiny" style={{margin: 'auto'}} as={Link} to="/"/>
      </Container>
      <div style={{paddingTop: 20, minHeight: '80vh'}}>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
